import * as Yup from 'yup';
import { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';

import { USER_TYPE } from '../constants';

const signupSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    userType: Yup.string(),
    school: Yup
        .string()
        .required('Required')
        .when(['userType'], {
            is: (userType: string) => {
                return userType === USER_TYPE.SCHOOL;
            },
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string(),
        }),
    university: Yup.string()
        .when(['userType'], {
            is: (userType: string) => userType === USER_TYPE.UNIVERSITY,
            then: () => Yup.string().required('Required'),
        }),
    department: Yup
        .string()
        .when(['userType'], {
            is: (userType: string) => userType === USER_TYPE.UNIVERSITY,
            then: () => Yup.string().required('Required'),
        }),
    phoneNumber: Yup
        .string()
        .required('Required')
        .test('validate-phone-number', function (value) {
            if (!isValidPhoneNumber(value) || !isPossiblePhoneNumber(value)) return this.createError({ message: 'Invalid mobile number', path: this.path });
            return true;
        }),
    password: Yup.string().required('Required'),
    confirmPassword: Yup.string().required('Required').oneOf([Yup.ref('password')], 'Passwords must match')
});

export default signupSchema;