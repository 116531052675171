import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';

import { logoo, eye } from '../../../assets/images';
import commonStyles from '../styles/common.module.css';
import { ROUTE_CONSTANTS } from '../../../routes/constants';
import { USER_TYPE } from '../constants';
import signupSchema from '../validations/signup';
import { getAllSchools, getAllTrainingFields, getAllUniversities, registerStudent, sendOTP } from '../api/auth';
import { NewStudentPayload, School, Training, University, UniversityDepartments } from '../api/interface';
import { STUDENT_TYPE } from '../../../utils/constants';
import OTP from './OTP';

const Signup = () => {

    const [otpScreen, showOTP] = useState<boolean>(false);

    const [schools, setSchools] = useState<School[]>([]);
    const [universities, setUniversities] = useState<University[]>([]);
    const [departments, setDepartments] = useState<UniversityDepartments[]>([]);
    const [trainingFields, setTrainingField] = useState<Training[]>([]);

    const {
        values,
        touched,
        handleChange,
        setFieldValue,
        errors,
        handleSubmit,
        resetForm,
    } = useFormik({
        initialValues: {
            userType: USER_TYPE.SCHOOL,
            firstName: '',
            lastName: '',
            school: '',
            phoneNumber: '',
            referredBy: '',
            password: '',
            confirmPassword: '',
            university: '',
            department: '',
            status: '',
            trainingField: '',
        },
        onSubmit: (values) => {
            const mobileNumber = parsePhoneNumber(values.phoneNumber);

            const payload = {
                country_code: `+${mobileNumber?.countryCallingCode as string}`,
                mobile: mobileNumber?.nationalNumber as string,
            };

            sendOTP(payload).then(res => {
                showOTP(true);
            });

        },
        validationSchema: signupSchema,
    });

    const createStudent = () => {
        const mobileNumber = parsePhoneNumber(values.phoneNumber);
    
        let payload: NewStudentPayload = {
            first_name: values.firstName,
            last_name: values.lastName,
            password: values.password,
            mobile: mobileNumber?.nationalNumber as string,
            country_code: mobileNumber?.countryCallingCode as string,
        };

        if (values.userType === USER_TYPE.SCHOOL) {
            payload.school_id = parseInt(values.school, 10);
            payload.student_type = STUDENT_TYPE.STUDENT;
        }
        else if (values.userType === USER_TYPE.UNIVERSITY) {
            payload.university_id = parseInt(values.university, 10);
            payload.university_dep_id = parseInt(values.department, 10);
            payload.student_type = STUDENT_TYPE.UNIVERSITY;
        }
        else {
            payload.status = 1;
            payload.training_field = parseInt(values.trainingField, 10);
            payload.student_type = STUDENT_TYPE.GENERAL;
        };

        toast.promise(
            registerStudent(payload),
            {
                pending: {
                    render() {
                        return 'Creating Student'
                    }
                },
                success: {
                    render() {
                        return 'Signup Successfull'
                    }
                },
                error: {
                    render () {
                        return 'Invalid Credentials'
                    }
                }
            }
        );
    };

    const handleUniversityChange = (event: any) => {

        const university = universities.find(item => item.id === parseInt(event.target.value)) as University;
        setDepartments(university?.universityDepartments);

        setFieldValue('department', '');
        setFieldValue('university', event.target.value);
    };

    const handleStudentTypeChange = (event: any) => {
        resetForm();

        setFieldValue('userType', event.target.value);
    };

    useEffect(() => {
        getAllSchools().then(res => setSchools(res.data));

        getAllUniversities().then(res => setUniversities(res.data));

        getAllTrainingFields().then(res => setTrainingField(res.data));
    }, []);

    if (otpScreen) {
        return <OTP mobileNumber={values.phoneNumber} createStudent={createStudent} />
    }

    return (
        <div className={`${commonStyles.loginouter} sign-up`}>
            <div className='container'>
                <div className='row '>
                    <div className='col-md-6'>
                        <div className={commonStyles['login-left']}>
                            <div className={commonStyles['top-lgo']}>
                                <img src={logoo} alt="" />
                            </div>
                            <h3>Welcome to Bright Future</h3>
                            <form onSubmit={handleSubmit}>
                                <div className='d-flex justify-content-between'>
                                    <div className="form-check">
                                        <input value={USER_TYPE.SCHOOL} onChange={handleStudentTypeChange} checked={values.userType === USER_TYPE.SCHOOL} className="form-check-input" type="radio" name="userType" id="school" />
                                        <label className="form-check-label" htmlFor="school">
                                            School
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input value={USER_TYPE.UNIVERSITY} onChange={handleStudentTypeChange} checked={values.userType === USER_TYPE.UNIVERSITY} className="form-check-input" type="radio" name="userType" id="university" />
                                        <label className="form-check-label" htmlFor="university">
                                            University
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input value={USER_TYPE.GENERAL} onChange={handleStudentTypeChange} checked={values.userType === USER_TYPE.GENERAL} className="form-check-input" type="radio" name="userType" id="general" />
                                        <label className="form-check-label" htmlFor="general">
                                            General
                                        </label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className={`form-group ${commonStyles['input-group']}`}>
                                            <label>First Name</label>
                                            <input type='text' name="firstName" value={values.firstName} onChange={handleChange} placeholder='Enter name here' />
                                            {touched.firstName && errors.firstName ? <p className={commonStyles.error}>{errors.firstName}</p> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className={`form-group ${commonStyles['input-group']}`}>
                                            <label>Last Name</label>
                                            <input type='text' name="lastName" value={values.lastName} onChange={handleChange} placeholder='Enter name here' />
                                            {touched.lastName && errors.lastName ? <p className={commonStyles.error}>{errors.lastName}</p> : null}
                                        </div>
                                    </div>
                                </div>
                                {values.userType === USER_TYPE.SCHOOL ?
                                    <Fragment>
                                        <div className={`form-group ${commonStyles['input-group']}`}>
                                            <label>Select School</label>
                                            <select name="school" onChange={handleChange}>
                                                <option value="">Select</option>
                                                {schools.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                                            </select>
                                            {touched.school && errors.school ? <p className={commonStyles.error}>{errors.school}</p> : null}
                                        </div>
                                    </Fragment>
                                    : null}
                                {
                                    values.userType === USER_TYPE.UNIVERSITY ?

                                        <Fragment>
                                            <div className={`form-group ${commonStyles['input-group']}`}>
                                                <label>Select University</label>
                                                <select name="university" value={values.university} onChange={handleUniversityChange}>
                                                    <option value="">Select</option>
                                                    {universities.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                                                </select>
                                                {touched.university && errors.university ? <p className={commonStyles.error}>{errors.university}</p> : null}
                                            </div>
                                            <div className={`form-group ${commonStyles['input-group']}`}>
                                                <label>Select Department</label>
                                                <select name="department" onChange={handleChange}>
                                                    <option value="">Select</option>
                                                    {departments.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                                                </select>
                                                {touched.department && errors.department ? <p className={commonStyles.error}>{errors.department}</p> : null}
                                            </div>
                                        </Fragment> : null
                                }
                                {
                                    values.userType === USER_TYPE.GENERAL ?
                                        <Fragment>
                                            <div className={`form-group ${commonStyles['input-group']}`}>
                                                <label>Select Training Field</label>
                                                <select name="trainingField" onChange={handleChange}>
                                                    <option value="">Select</option>
                                                    {trainingFields.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                                                </select>
                                            </div>
                                            {touched.trainingField && errors.trainingField ? <p className={commonStyles.error}>{errors.trainingField}</p> : null}
                                        </Fragment> : null
                                }

                                <div className={`form-group ${commonStyles['input-group']}`}>
                                    <label>Phone Number</label>
                                    <PhoneInput
                                        placeholder="Enter phone number"
                                        onChange={(value) => setFieldValue('phoneNumber', value)}
                                        value={values.phoneNumber}
                                        defaultCountry="US"
                                    />
                                    {errors.phoneNumber ? <p className={commonStyles.error}>{errors.phoneNumber}</p> : null}
                                </div>

                                <div className={`form-group ${commonStyles['input-group']}`}>
                                    <label>Refered By</label>
                                    <input type='text' name="referredBy" value={values.referredBy} onChange={handleChange} placeholder='Enter refferal here' />
                                </div>
                                <div className={`form-group mb-2 ${commonStyles['input-group']}`}>
                                    <label>Password</label>
                                    <input type='password' name="password" onChange={handleChange} value={values.password} placeholder='input your password in here' />
                                    <span> <img src={eye} alt="" /></span>
                                    {touched.password && errors.password ? <p className={commonStyles.error}>{errors.password}</p> : null}

                                </div>
                                <div className={`form-group mb-2 ${commonStyles['input-group']}`}>
                                    <label>Confirm Password</label>
                                    <input type='password' name="confirmPassword" onChange={handleChange} value={values.confirmPassword} placeholder='input your password in here' />
                                    <span><img src={eye} alt="" /></span>
                                    {touched.confirmPassword && errors.confirmPassword ? <p className={commonStyles.error}>{errors.confirmPassword}</p> : null}
                                </div>

                                <div className='form-group'>
                                    <input type='submit' value="Get OTP" />
                                    {/* <input type='button' onClick={() => resetForm()} value="Test" /> */}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className={commonStyles['login-right']}>
                            <Link to={ROUTE_CONSTANTS.LOGIN}>SIGN IN</Link>
                            <h3>Bright Future <br />Institute</h3>
                            <p>Sign Up to continue access pages</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup