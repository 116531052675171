import { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

const loginSchema = Yup.object().shape({
    phoneNumber: Yup
        .string()
        .required('Required')
        .test('validate-number', function (value) {
            if (!isValidPhoneNumber(value) || !isPossiblePhoneNumber(value)) return this.createError({ message: 'Invalid mobile number', path: this.path });
            return true;

        }),
    password: Yup.string().required('Required')
});

export default loginSchema;