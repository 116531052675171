import { axios } from "../../../lib/axios";
import {
    NewStudentPayload,
    SchoolListResponse,
    TrainingListResponse,
    UniversitiesListResponse,
    SendOTPPayload,
    VerifyOTPPayload,
    LoginStudentPayload,
    LoginResponse
} from "./interface";

export const getAllUniversities = (): Promise<UniversitiesListResponse> => {
    return axios.get('/universities');
};

export const getAllSchools = (): Promise<SchoolListResponse> => {
    return axios.get('/schools-list');
};

export const getAllTrainingFields = (): Promise<TrainingListResponse> => {
    return axios.get('/training-fields');
};

export const registerStudent = (payload: NewStudentPayload) => {
    return axios.post('/student/register', payload);
};

export const sendOTP = (payload: SendOTPPayload) => {
    return axios.post('/send-otp', payload);
};

export const verifyOTP = (payload: VerifyOTPPayload) => {
    return axios.post('/verify-otp', payload);
};

export const login = (payload: LoginStudentPayload): Promise<LoginResponse> => {
    return axios.post('/login', payload);
};