import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import styles from '../styles/login.module.css';
import commonStyles from '../styles/common.module.css';
import { logoo, eye, google, facebook, apple } from '../../../assets/images';
import { ROUTE_CONSTANTS } from '../../../routes/constants';
import loginSchema from '../validations/login';
import { login } from '../api/auth';
import storage from '../../../utils/storage';
import { LoginStudentSuccessResponse } from '../api/interface';

const Login = () => {
    const navigate = useNavigate();

    const [passwordType, setPasswordType] = useState<string>('password');

    const loginFormik = useFormik({
        initialValues: { phoneNumber: '', password: '' },
        onSubmit: (values, { setSubmitting }) => {
            const mobileNumber = parsePhoneNumber(values.phoneNumber);

            const payload = {
                country_code: mobileNumber?.countryCallingCode as string,
                password: values.password,
                mobile: mobileNumber?.nationalNumber as string,
            };

            toast.promise(
                login(payload),
                {
                    pending: {
                        render() {
                            return 'Verifying Student'
                        }
                    },
                    success: {
                        render({ data }) {
                            let response = data as LoginStudentSuccessResponse;

                            setSubmitting(false);
                            storage.setToken(response.authorization.token);

                            loginFormik.resetForm();

                            navigate('/');
                            return 'Login Successful'
                        }
                    },
                    error: {
                        render({ data }) {
                            setSubmitting(false);

                            return 'Invalid Credentials'
                        }
                    }
                }
            );
        },
        validationSchema: loginSchema,
    });

    const handlePasswordTypeChange = () => {
        if (passwordType === 'password') setPasswordType('text');
        else setPasswordType('password');
    };

    return (
        <div className={commonStyles.loginouter}>
            <div className='container'>
                <div className='row d-flex align-items-center'>
                    <div className='col-md-6'>
                        <div className={commonStyles['login-left']}>
                            <div className={commonStyles['top-lgo']}>
                                <img src={logoo} alt="logo" />
                            </div>
                            <h3>Welcome Back</h3>
                            <form onSubmit={loginFormik.handleSubmit}>
                                <div className={`form-group ${commonStyles['input-group']}`}>
                                    <label>Phone</label>
                                    <PhoneInput
                                        placeholder="Enter phone number"
                                        onChange={(value) => loginFormik.setFieldValue('phoneNumber', value)}
                                        value={loginFormik.values.phoneNumber}
                                    />
                                    {loginFormik.errors.phoneNumber ? <p className={commonStyles.error}>{loginFormik.errors.phoneNumber}</p> : null}
                                </div>
                                <div className={`form-group ${commonStyles['input-group']} mb-2`}>
                                    <label>Password</label>
                                    <input
                                        type={passwordType}
                                        placeholder='input your password in here'
                                        name="password"
                                        onChange={loginFormik.handleChange}
                                        onBlur={loginFormik.handleBlur}
                                        value={loginFormik.values.password}
                                    />
                                    <span onClick={handlePasswordTypeChange}> <img src={eye} alt="" /></span>
                                    {loginFormik.touched.password && loginFormik.errors.password ? <p className={commonStyles.error}>{loginFormik.errors.password}</p> : null}
                                </div>
                                <div className='form-group'>
                                    <div className={styles['forgot-pass']}>
                                        <div>
                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Remember me</label>
                                        </div>
                                        <div>
                                            <p className='mb-0 cursor-pointer'>Forgot password ?</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`form-group ${commonStyles['input-group']}`}>
                                    <input type='submit' value="Sign in" disabled={loginFormik.isSubmitting} />
                                </div>
                                <div className={commonStyles['or-val']}>
                                    <span>Or continue with</span>
                                    <hr />
                                </div>
                                <div className={commonStyles['logins-social']}>
                                    <a href='/'><img src={google} alt="" />Google</a>
                                    <a href='/'><img src={facebook} alt="" />Facebook</a>
                                    <a href='/'><img src={apple} alt="" />Apple</a>
                                </div>
                            </form>

                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className={commonStyles['login-right']}>
                            <Link to={ROUTE_CONSTANTS.SIGNUP}>SIGN UP</Link>
                            <h3>Bright Future <br />Institute</h3>
                            <p>Sign in to continue access pages</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login