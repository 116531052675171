import { Link } from 'react-router-dom';

import { phn, env, logowhite, search, heart } from '../../../assets/images';
import styles from '../styles/header.module.css';
import { ROUTE_CONSTANTS } from '../../../routes/constants';

const Header = () => {
    return (
        <div className={styles['web-header']}>
            <div className={styles['op-head-bar']}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className={styles['top-barleft']}>
                                <ul>
                                    <li><img src={phn} alt="phone-no" />+91 0987654321</li>
                                    <li><img src={env} alt="message" />+91 0987654321</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className={styles['top-barright']}>
                                <ul>
                                    <li>Follow Us:</li>
                                    <li><Link to=""><i className="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to=""><i className="fab fa-twitter"></i></Link></li>
                                    <li><Link to=""><i className="fab fa-instagram"></i></Link></li>
                                    <li><Link to=""><i className="fab fa-youtube"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles['link-header']}>
                <div className='container'>
                    <div className={styles['outer-head']}>
                        <nav className="navbar navbar-expand-lg">
                            <a className="navbar-brand" href="/"><img src={logowhite} alt="" /></a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className={`navbar-nav ${styles['header-nav']}`}>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/">ABout us</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/">Instructors</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/">Admissions</a>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            UNIVERTIES
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><a className="dropdown-item" href="/">Action</a></li>
                                            <li><a className="dropdown-item" href="/">Another action</a></li>
                                            <li><a className="dropdown-item" href="/">Something else here</a></li>
                                        </ul>
                                    </li>

                                </ul>

                            </div>

                        </nav>
                        <div className={styles['search-btn']}>
                            <div className={styles['search']}>
                                <span><img src={search} alt="search-icon" /></span>
                                <span><img src={heart} alt="heart-icon" /></span>
                            </div>
                            <div className={styles['login-btn']}>
                                <Link to={ROUTE_CONSTANTS.LOGIN}>Login / Register</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;