import { Link } from "react-router-dom";

import { logowhite } from '../../../assets/images';
import styles from '../styles/footer.module.css';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className='container'>
                <div className='row '>
                    <div className='col-md-3'>
                        <div className={styles['inner-list-ft']}>
                            <h4>About</h4>
                            <ul>
                                <li><Link to="" >About us</Link></li>
                                <li><Link to="" >Blog</Link></li>
                                <li><Link to="" >Careers</Link></li>
                                <li><Link to="" >Jobs</Link></li>
                                <li><Link to="" >In Press</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className={styles['inner-list-ft']}>
                            <h4>Quick Links</h4>
                            <ul>
                                <li><Link to="" >Refund Policy</Link></li>
                                <li><Link to="" >Documentaion</Link></li>
                                <li><Link to="" >Chat online</Link></li>
                                <li><Link to="" >Order Cancel</Link></li>
                                <li><Link to="" >Privacy Policy</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className={styles['inner-list-ft']}>
                            <h4>Support</h4>
                            <ul>
                                <li><Link to="" >Contact Us</Link></li>
                                <li><Link to="" >Documentaion</Link></li>
                                <li><Link to="" >Chat online</Link></li>
                                <li><Link to="" >Telegram</Link></li>
                                <li><Link to="" >Whatsapp</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className={styles['inner-list-ft']}>
                            <h4>FAQ</h4>
                            <ul>
                                <li><Link to="" >Account</Link></li>
                                <li><Link to="" >Deliveries</Link></li>
                                <li><Link to="" >Order</Link></li>
                                <li><Link to="" >Payment</Link></li>
                                <li><Link to="" >Return</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className={styles['inner-list-ft']}>
                            <h4>Product</h4>
                            <ul>
                                <li><Link to="" >Overview</Link></li>
                                <li><Link to="" >Business Account</Link></li>
                                <li><Link to="" >Credit card</Link></li>
                                <li><Link to="" >Payment</Link></li>
                                <li><Link to="" >Reward</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='copy-right'>
                <div className='container'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-md-4'>
                            <div className='f-log'>
                                <img src={logowhite} alt="" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className={styles['f-center']}>
                                <p>Brightfuture@ 2024, All rights Reserved</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className={styles['foter-social']}>
                                <ul>
                                    <li>Follow Us:</li>
                                    <li><Link to=""><i className="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to=""><i className="fab fa-twitter"></i></Link></li>
                                    <li><Link to=""><i className="fab fa-instagram"></i></Link></li>
                                    <li><Link to=""><i className="fab fa-youtube"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;