import Login from "../features/auth/routes/Login";
import Signup from "../features/auth/routes/Signup";
import Home from '../features/Home/routes';

export const publicRoutes = [
    { path: '/', element: <Home /> },
    { path: '/login', element: <Login /> },
    { path: '/signup', element: <Signup /> }
];


