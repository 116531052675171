import { useRoutes } from 'react-router-dom';

import { publicRoutes } from './public';

const AppRoutes = () => {
    const appRoutes = publicRoutes;

    const routes = useRoutes(appRoutes);

    return <>{routes}</>
};

export default AppRoutes;