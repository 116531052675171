import { useFormik } from 'formik';
import OtpInput from 'react-otp-input';
import { parsePhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';

import { logoo } from '../../../assets/images';
import commonStyles from '../styles/common.module.css';
import styles from '../styles/otp.module.css';
import otpSchema from '../validations/otp';
import { verifyOTP } from '../api/auth';
import { APIResponseError } from '../api/interface';

interface Props {
    mobileNumber: string;
    createStudent?: () => void;
};

const OTP = (props: Props) => {

    const otpFormik = useFormik({
        initialValues: { otp: '' },
        onSubmit: async (values, { resetForm }) => {
            otpFormik.setSubmitting(true);

            const mobileNumber = parsePhoneNumber(props.mobileNumber);

            const payload = {
                country_code: `+${mobileNumber?.countryCallingCode as string}`,
                mobile: mobileNumber?.nationalNumber as string,
                otp: parseInt(values.otp, 10),
            };

            await toast.promise(
                verifyOTP(payload),
                {
                    pending: {
                        render() {
                            return 'Verifying'
                        }
                    },
                    success: {
                        render() {
                            if (props.createStudent) {
                                props.createStudent();
                                resetForm();
                            }
                            return 'Verified'
                        }
                    },
                    error: {
                        render({ data }) {
                            const response = data as APIResponseError;


                            otpFormik.setSubmitting(false);
                            return response.data.message;
                        }
                    }
                },
            );
        },
        validationSchema: otpSchema,
    });

    return (
        <div className={commonStyles.loginouter}>
            <div className='container'>
                <div className='row d-flex align-items-center'>
                    <div className='col-md-6'>
                        <div className={commonStyles['login-left']}>
                            <div className={commonStyles['top-lgo']}>
                                <img src={logoo} alt="" />
                            </div>
                            <h3>Verification</h3>
                            <form onSubmit={otpFormik.handleSubmit} className={styles.otp}>
                                <div className={styles['otp-field']}>
                                    <OtpInput
                                        value={otpFormik.values.otp}
                                        onChange={(value) => otpFormik.setFieldValue('otp', value)}
                                        numInputs={4}
                                        renderInput={(props: any) => <input {...props} />}
                                    />
                                    {otpFormik.touched.otp && otpFormik.errors.otp ? <p className={`${commonStyles.error} text-center`}>{otpFormik.errors.otp}</p> : null}
                                </div>

                                <div className='form-group'>
                                    <input type='submit' value="Verify" />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className={commonStyles['login-right']}>
                            {/* <Link to="/home">SIGN UP</Link> */}
                            <h3>Bright Future <br />Institute</h3>
                            <p>Sign in to continue access pages</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OTP;