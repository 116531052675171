import { Fragment } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Header from "../components/Header";
import { shape, book, about, cup, user, save, crone, rating, join, team } from '../../../assets/images';
import styles from '../styles/home.module.css';
import Footer from "../components/Footer";

const Home = () => {
    const settings = {
        dots: true,
        nav: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4
    };


    return (
        <Fragment>
            <Header />

            <section className={styles['banner-main']}>
                <img src={shape} alt="" />
                <div className='container'>
                    <div className={styles['text-overlay']}>
                        <h6>LEARN FROM TODAY</h6>
                        <h2>Education Is Create
                            Better <span>Future.</span></h2>
                        <p>Education can be thought of as the transmission of the values and accumulated knowledge of a society. In this sense, it is equivalent.</p>
                    </div>
                </div>
            </section>

            <section className={styles['case-study']}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className={styles['left-case']}>
                                <h6><img src={book} alt="" />Courses Categories</h6>
                                <h3>Explore top Categories</h3>
                                <Link className={styles['cm-btn']} to="" >View all Category <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className={styles['right-case']}>
                                <Slider {...settings}>
                                    <div className={styles['innder-cat']}>
                                        <div className={styles['sha-in-cat']}>
                                            <h5>Digital Marketing</h5>
                                            <p>51+ Courses</p>
                                            <Link className={styles['cm-btn']} to="" >Learn more <i className="fas fa-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className={styles['innder-cat']}>
                                        <div className={styles['sha-in-cat']}>
                                            <h5>Graphic Designer</h5>
                                            <p>51+ Courses</p>
                                            <Link className={styles['cm-btn']} to="" >Learn more <i className="fas fa-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className={styles['innder-cat']}>
                                        <div className={styles['sha-in-cat']}>
                                            <h5>Web Development</h5>
                                            <p>56+ Courses</p>
                                            <Link className={styles['cm-btn']} to="" >Learn more <i className="fas fa-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className={styles['innder-cat']}>
                                        <div className={styles['sha-in-cat']}>
                                            <h5>UI/UX Designer</h5>
                                            <p>36+ Courses</p>
                                            <Link className={styles['cm-btn']} to="" >Learn more <i className="fas fa-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className={styles['innder-cat']}>
                                        <div className={styles['sha-in-cat']}>
                                            <h5>UI/UX Designer</h5>
                                            <p>36+ Courses</p>
                                            <Link className={styles['cm-btn']} to="" >Learn more <i className="fas fa-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className={styles['innder-cat']}>
                                        <div className={styles['sha-in-cat']}>
                                            <h5>UI/UX Designer</h5>
                                            <p>36+ Courses</p>
                                            <Link className={styles['cm-btn']} to="" >Learn more <i className="fas fa-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles['about-sec']}>
                <div className='container'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-md-6'>
                            <div className={styles['about-left']}>
                                <img src={about} alt="" />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className={styles['about-right']}>
                                <h6><img src={book} alt="" />About Our University</h6>
                                <h3>Welcome to Bright <br />Future University.</h3>
                                <p>Collaboratively simplify user friendly networks after principle centered coordinate effective methods of empowerment distributed niche markets pursue market positioning web-readiness after resource sucking applications.</p>
                                <p>Online education, also known as e-learning, is a method of learning that takes place over the internet. It offers individuals the opportunity to acquire knowledge, skills.</p>
                                <Link className={styles['cm-btn']} to="" >ABOUT MORE <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles['about-sec']}>
                <div className='container'>
                    <h6><img src={book} alt="" />POPULAR COURSES</h6>
                    <h3>Our Popular Courses</h3>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className={styles['about-inner']}>
                                <div className={styles['pro-img']}>
                                    <img src={crone} alt="" />
                                </div>
                                <h5>Learn Figma - UI/UX Design Essential Training</h5>
                                <ul>
                                    <li><img src={cup} alt="" />Lesson : 6</li>
                                    <li><img src={user} alt="" />student : 198</li>
                                    <li><img src={save} alt="" />Beginner</li>
                                </ul>
                                <div className={styles['btn-butn']}>
                                    <Link to="">Start course <i className="fas fa-arrow-right"></i></Link>
                                    <img src={rating} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className={styles['about-inner']}>
                                <div className={styles['pro-img']}>
                                    <img src={crone} alt="" />
                                </div>
                                <h5>Learn Figma - UI/UX Design Essential Training</h5>
                                <ul>
                                    <li><img src={cup} alt="" />Lesson : 6</li>
                                    <li><img src={user} alt="" />student : 198</li>
                                    <li><img src={save} alt="" />Beginner</li>
                                </ul>
                                <div className={styles['btn-butn']}>
                                    <Link to="">Start course <i className="fas fa-arrow-right"></i></Link>
                                    <img src={rating} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className={styles['about-inner']}>
                                <div className={styles['pro-img']}>
                                    <img src={crone} alt="" />
                                </div>
                                <h5>Learn Figma - UI/UX Design Essential Training</h5>
                                <ul>
                                    <li><img src={cup} alt="" />Lesson : 6</li>
                                    <li><img src={user} alt="" />student : 198</li>
                                    <li><img src={save} alt="" />Beginner</li>
                                </ul>
                                <div className={styles['btn-butn']}>
                                    <Link to="">Start course <i className="fas fa-arrow-right"></i></Link>
                                    <img src={rating} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className={styles['about-inner']}>
                                <div className={styles['pro-img']}>
                                    <img src={crone} alt="" />
                                </div>
                                <h5>Learn Figma - UI/UX Design Essential Training</h5>
                                <ul>
                                    <li><img src={cup} alt="" />Lesson : 6</li>
                                    <li><img src={user} alt="" />student : 198</li>
                                    <li><img src={save} alt="" />Beginner</li>
                                </ul>
                                <div className={styles['btn-butn']}>
                                    <Link to="">Start course <i className="fas fa-arrow-right"></i></Link>
                                    <img src={rating} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className={styles['about-inner']}>
                                <div className={styles['pro-img']}>
                                    <img src={crone} alt="" />
                                </div>
                                <h5>Learn Figma - UI/UX Design Essential Training</h5>
                                <ul>
                                    <li><img src={cup} alt="" />Lesson : 6</li>
                                    <li><img src={user} alt="" />student : 198</li>
                                    <li><img src={save} alt="" />Beginner</li>
                                </ul>
                                <div className={styles['btn-butn']}>
                                    <Link to="">Start course <i className="fas fa-arrow-right"></i></Link>
                                    <img src={rating} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className={styles['about-inner']}>
                                <div className={styles['pro-img']}>
                                    <img src={crone} alt="" />
                                </div>
                                <h5>Learn Figma - UI/UX Design Essential Training</h5>
                                <ul>
                                    <li><img src={cup} alt="" />Lesson : 6</li>
                                    <li><img src={user} alt="" />student : 198</li>
                                    <li><img src={save} alt="" />Beginner</li>
                                </ul>
                                <div className={styles['btn-butn']}>
                                    <Link to="">Start course <i className="fas fa-arrow-right"></i></Link>
                                    <img src={rating} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className={styles['about-inner']}>
                                <div className={styles['pro-img']}>
                                    <img src={crone} alt="" />
                                </div>
                                <h5>Learn Figma - UI/UX Design Essential Training</h5>
                                <ul>
                                    <li><img src={cup} alt="" />Lesson : 6</li>
                                    <li><img src={user} alt="" />student : 198</li>
                                    <li><img src={save} alt="" />Beginner</li>
                                </ul>
                                <div className={styles['btn-butn']}>
                                    <Link to="">Start course <i className="fas fa-arrow-right"></i></Link>
                                    <img src={rating} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className={styles['about-inner']}>
                                <div className={styles['pro-img']}>
                                    <img src={crone} alt="" />
                                </div>
                                <h5>Learn Figma - UI/UX Design Essential Training</h5>
                                <ul>
                                    <li><img src={cup} alt="" />Lesson : 6</li>
                                    <li><img src={user} alt="" />student : 198</li>
                                    <li><img src={save} alt="" />Beginner</li>
                                </ul>
                                <div className={styles['btn-butn']}>
                                    <Link to="">Start course <i className="fas fa-arrow-right"></i></Link>
                                    <img src={rating} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles['join-esion']}>
                <img src={join} alt="" />
            </section>

            <section className={styles['about-sec']}>
                <div className='container'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-md-6'>
                            <div className={styles['about-left']}>
                                <img src={team} alt="" />
                                <Link className={styles['cm-btn']} to="" >GET STARTED <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className={styles['about-right']}>
                                <h6><img src={book} alt="" />WHY CHOOSE US</h6>
                                <h3>Thousands Of Experts<br /> Around The World <span>Ready To Help.</span></h3>
                                <p>Synergistically visualize alternative content before cross functional core Rapidiously administra standardized value via focused benefits. Rapidiously redefine highly efficient niche markets with plug-and-play materials professionally seize client centric solutions</p>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className={styles['inner-right-box']}>
                                            <h5><i className="fas fa-check-circle"></i>World CLass Trainers</h5>
                                            <span>Seamlessly envisioneer tactical data through services.</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className={styles['inner-right-box']}>
                                            <h5><i className="fas fa-check-circle"></i>Easy Learning</h5>
                                            <span>Seamlessly envisioneer tactical data through services.</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className={styles['inner-right-box']}>
                                            <h5><i className="fas fa-check-circle"></i>Flexible</h5>
                                            <span>Seamlessly envisioneer tactical data through services.</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className={styles['inner-right-box']}>
                                            <h5><i className="fas fa-check-circle"></i>Affordable Price</h5>
                                            <span>Seamlessly envisioneer tactical data through services.</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles['counter']}>
                <div className='container'>
                    <ul>
                        <li>
                            <h5>3.9k+</h5>
                            <span>Successfully Trained</span>
                        </li>
                        <li>
                            <h5>15.8k+</h5>
                            <span>Classes Completed</span>
                        </li>
                        <li>
                            <h5>97.5k+</h5>
                            <span>Satifaction Rate</span>
                        </li>
                        <li>
                            <h5>100.2k+</h5>
                            <span>Students Community</span>
                        </li>
                    </ul>
                </div>
            </section>

            <section className={styles['student-dec']}>
                <div className='container'>
                    <h6><img src={book} alt="" /> POPULAR COURSES</h6>
                    <h3>40% Offer First 100 Student’s For Featured <br /><span>Topics by Education Category</span></h3>
                    <p>Synergistically visualize alternative content before cross functional core Rapidiously administra standardized value via focused benefits. Rapidiously redefine highly efficient niche markets with plug-and-play materials professionally seize client centric solutions</p>
                    <div className={styles['btn-list']}>
                        <Link to="">JOIN WITH US <i className="fas fa-arrow-right"></i></Link>
                        <Link to="">BEACOME A TEACHER <i className="fas fa-arrow-right"></i></Link>
                    </div>
                </div>
            </section>

            <Footer />
        </Fragment>
    );
};

export default Home;